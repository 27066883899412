import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useI18n, useLanguage } from 'gatsby-theme-wordpress/src/context/language';
import MapMarker from 'gatsby-theme-wordpress/src/components/icons/map-marker';

const Title = styled.p`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  margin-top: 0;
  ${MapMarker} {
    width: 12px;
    margin-right: 12px;
    color: #88b526;
  }
`;

const Addresses = ({ className }) => {
  const language = useLanguage();
  const i18n = useI18n();
  const { options } = useStaticQuery(graphql`
    query Addresses {
      options: allWordpressPage(filter: {
        template: { eq: "templates/template-homepage.php" }
      }) {
        edges {
          node {
            wpml_current_locale
            acf {
              locations: contact_locations {
                title
                address {
                  line
                }
                phone
                email
              }
            }
          }
        }
      }
    }
  `);
  const option = options.edges?.find(({ node }) => node.wpml_current_locale === language);

  return (
    <div className={className}>
      {(option?.node?.acf?.locations || []).map(({
        title,
        address,
        phone,
        email,
      }) => (
        <div key={title}>
          <Helmet>
            <link href="https://fonts.googleapis.com/css?family=Montserrat:700&display=swap" rel="stylesheet" />
          </Helmet>
          <Title>
            <MapMarker />
            {title}
          </Title>
          <div css="padding-left: 24px">
            <address css="font-style: normal">
              {(address || []).map(({ line }) => <Fragment key={line}>{line}<br /></Fragment>)}
            </address>
            {phone && (
              <p css={email ? 'margin-bottom: 0;' : null}>
                <span>{i18n?.phone}: </span>
                <a href={`tel:${phone.replace(/\s/g, '')}`}>{phone}</a>
              </p>
            )}
            {email && (
              <p css={phone ? 'margin-top: 0;' : null}>
                <span>{i18n?.email}: </span>
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default styled(Addresses)`
  display: grid;
  grid-gap: 30px;
  @media screen and (min-width: 990px) {
    grid-template-columns: 1fr 1fr;
  }
`;
